<template>
    <div style="position:relative;"  class="digital-docketing">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <div>
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Embrace the Future with Digital Docketing</h1>
                    <p>                
                        Businesses in every industry are actively seeking efficient solutions to streamline their operations and eliminate the hassle of handling disorganised paperwork. To meet this demand, Record Time has brought a remarkable feature called Digital Docketing. Whether you work in manufacturing, construction, cleaning, transportation, or healthcare, Digital Docketing is here to process and drive your <a href="https://recordtime.com.au/about">business</a> into the digital age.
                    </p>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/blog/digital-docketing-with-record-time')" class="mr-7 btn-1" elevation="0">
                        Read More
                    </v-btn>
            <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                Get A Demo Now
            </v-btn>
                </div>
            </div>
            <div  class="right-banner">
                <img :src="`${publicPath}assets/features-image/digita-docketing.webp`" alt="eliminate the need for paper documents with digital docketing"  style="max-width: 100%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        <v-container>
            <div class="gray-container">
                <h3>Why Should You Consider  <span>Digital Forms and Dockets?</span></h3>
                <img :src="`${publicPath}assets/features-image/why-digital-forms-and-dockets.webp`" alt="why digital forms and dockets" style="margin-top: 20px; object-fit: contain;width: 100%;">
                </div>
            <br/><br/>
            <h3>Say goodbye to the paper document chaos and make the switch to Digital Docketing today!</h3>
            <br/><br/>
            <h2>Enhancing Efficiency and Collaboration with Digital Docketing</h2><br/>
            <p>Employees can easily create and submit reports, proof of work, pre start checklists, incident reports, and other important documents in real-time using digital docketing. This not only eliminates the need for physical paperwork but also reduces the chance of errors. Moreover, managers can swiftly access and review the dockets through digital forms and dockets, enhancing communication, teamwork, and decision-making. Overall, Record TIMEs' digital docketing strategy significantly improves and streamlines the documentation workflow, enabling employees and managers to handle the necessary paperwork in their respective industries efficiently in real time.</p>
            <br/><br/>
            <h2>A Comprehensive Overview of Digital Forms and Dockets Templates</h2><br/>


            <div class="left-blog1">
                <img :src="`${publicPath}assets/features-image/digital-form-and-dockets-template.webp`" alt="pre-designed digital forms and dockets templates for your need"  style="width: 100%; margin-bottom: 20px;">
            </div>

            <div class="right-blog1">
                <p>Record TIME's digital forms and docket templates provide a well-organised framework for storing, organising, and managing docket data. Additionally, you can design your digital forms and dockets to look and work exactly the way you want them.
                <br/>
                <br/>
                Record TIME provides a wide variety of pre-designed digital forms and docket templates for different purposes. Moreover, users can easily modify these templates to meet their unique needs. Apart from this, users can also download their dockets into professional-looking PDF files with just one click.
                <br/>
                <br/>
                In addition to PDF downloads, Record Time goes a step further by offering QR code generation. Users can quickly scan a QR code to open docket templates and start filling them, eliminating an extra step.
                </p>
            </div>
           
            <div style="clear:both;"></div>
        </v-container>
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>
</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 60%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 40%; 
        float: right;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .digital-docketing{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      padding-right: 15px;
      width: 500px;

    }
    .right-blog1{
      max-width: calc(100% - 500px);
      float:right;
    }

    @media only screen and (max-width: 960px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        width: 100%;
        text-align: center;

        padding-right: 0px;
        img{
            max-width: 500px;
        }
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }

    @media only screen and (max-width: 700px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';
export default{
    metaInfo: {
        title: 'Digital Docketing | Simplify Operation with Digital Forms and Dockets',
        // titleTemplate: 'Record TIME → %s',
        meta: [
            {name: 'name', content: ''},
            {name: 'description', content: 'Record TIMEs digital docketing approach provides a paperless solution for your documentation needs. With digital forms and dockets, you can save time and money.'},
            {name: 'keyword', content: 'digital docketing, digital forms and dockets'}
        ],
        link: [
            { rel: 'canonical', href: 'https://recordtime.com.au/digital-docketing' }
        ]
    },
    components: {
        GetDemoModal
        
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },
        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
